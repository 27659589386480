<script>
import { DateTime } from "luxon";
import api from "@/api.js";

export default {
    components: {},
    data() {
        return {
            isResizeLoading: false,
            dataLoading: true,
            deployments: [],
            hashServices: {},
            clusters: [],
            filter: "",
            serviceApiAndFrontList: ["investigation", "remediation"],
            serviceAppList: ["toucan"],
            serviceFrontList: ["draft"]
        };
    },
    methods: {
        async loadData() {
            const token = await this.$auth.getTokenSilently();

            this.deployments = (
                await api.get("/v1/deployments", {
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                    params: {
                        clusters:
                            "projects/toundra-185412/locations/europe-west1/clusters/toundra-02",
                        label_selector: "app.cybelangel.com/team=drps-web"
                    }
                })
            ).data;

            // Create a hash with key = service-name and value = service informations
            this.hashServices = this.deployments.reduce(
                (map, deployment) => (
                    (map[deployment.name] = deployment.clusters["toundra-02"]),
                    map
                ),
                {}
            );

            this.dataLoading = false;
        },

        updateOldestDeployments(matrix) {
            [
                ...this.serviceApiAndFrontList,
                ...this.serviceAppList,
                ...this.serviceFrontList
            ].forEach(column => {
                const minDate = matrix.reduce(
                    (min, row) =>
                        !min || row[column].last_deployment < min
                            ? row[column].last_deployment
                            : min,
                    null
                );

                matrix.forEach(row => {
                    row[column].is_oldest =
                        row[column].last_deployment.toMillis() ===
                        minDate.toMillis();
                });
            });
            return matrix;
        }
    },

    computed: {
        DateTime() {
            return DateTime;
        },

        tableData() {
            let tableRows = [];

            for (const prefix of ["a", "b", "c"]) {
                let row = {};

                row.env = prefix;

                for (const service of this.serviceApiAndFrontList) {
                    try {
                        const serviceNameApi = `${service}-${prefix}-api`;
                        const serviceNameFront = `${service}-${prefix}-front`;

                        // Get the last_deployment from front and api and get the max of the two
                        const lastDeploymentApi = DateTime.fromISO(
                            this.hashServices[serviceNameApi].last_deployment
                        );
                        const lastDeploymentFront = DateTime.fromISO(
                            this.hashServices[serviceNameFront].last_deployment
                        );

                        row[`${service}`] = {
                            last_deployment: DateTime.max(
                                lastDeploymentFront,
                                lastDeploymentApi
                            ),
                            is_oldest: false
                        };
                    } catch (error) {
                        row[`${service}`] = {
                            last_deployment: "",
                            is_oldest: false
                        };
                    }
                }

                for (const service of this.serviceFrontList) {
                    try {
                        const serviceNameFront = `${service}-${prefix}-front`;

                        const lastDeploymentFront = DateTime.fromISO(
                            this.hashServices[serviceNameFront].last_deployment
                        );

                        row[`${service}`] = {
                            last_deployment: lastDeploymentFront,
                            is_oldest: false
                        };
                    } catch (error) {
                        row[`${service}`] = {
                            last_deployment: "",
                            is_oldest: false
                        };
                    }
                }

                for (const serviceAppName of this.serviceAppList) {
                    row[serviceAppName] = {
                        last_deployment: DateTime.fromISO(
                            this.hashServices[`${serviceAppName}-${prefix}`]
                                .last_deployment
                        ),
                        is_oldest: false
                    };
                }

                tableRows.push(row);
            }
            this.updateOldestDeployments(tableRows);

            return tableRows;
        }
    },
    created() {
        this.loadData();

        setInterval(
            function() {
                this.loadData();
            }.bind(this),
            30000
        );
    }
};
</script>

<template>
    <div class="container">
        <b-table
            v-if="!dataLoading"
            :data="tableData"
            :striped="true"
            default-sort="name"
            focusable
        >
            <b-table-column field="env" label="Env" v-slot="props">
                {{ props.row.env }}
            </b-table-column>
            <b-table-column field="toucan" label="Toucan" v-slot="props">
                <span
                    :class="[
                        {
                            tag: props.row.toucan.is_oldest,
                            'is-large': props.row.toucan.is_oldest
                        },
                        {
                            'is-success':
                                DateTime.now().diff(
                                    props.row.toucan.last_deployment,
                                    'minutes'
                                ).minutes > 90
                        },
                        {
                            'is-danger':
                                DateTime.now().diff(
                                    props.row.toucan.last_deployment,
                                    'minutes'
                                ).minutes < 30
                        }
                    ]"
                >
                    {{
                        props.row.toucan.last_deployment
                            .setLocale("fr")
                            .toLocaleString(DateTime.DATETIME_SHORT)
                    }}
                </span>
            </b-table-column>
            <b-table-column
                field="investigation"
                label="Investigation"
                v-slot="props"
            >
                <span
                    :class="[
                        {
                            tag: props.row.investigation.is_oldest,
                            'is-large': props.row.investigation.is_oldest
                        },
                        {
                            'is-success':
                                DateTime.now().diff(
                                    props.row.investigation.last_deployment,
                                    'minutes'
                                ).minutes > 90
                        },
                        {
                            'is-danger':
                                DateTime.now().diff(
                                    props.row.investigation.last_deployment,
                                    'minutes'
                                ).minutes < 30
                        }
                    ]"
                >
                    {{
                        props.row.investigation.last_deployment
                            .setLocale("fr")
                            .toLocaleString(DateTime.DATETIME_SHORT)
                    }}
                </span>
            </b-table-column>
            <b-table-column field="draft" label="Draft" v-slot="props">
                <span
                    :class="[
                        {
                            tag: props.row.draft.is_oldest,
                            'is-large': props.row.draft.is_oldest
                        },
                        {
                            'is-success':
                                DateTime.now().diff(
                                    props.row.draft.last_deployment,
                                    'minutes'
                                ).minutes > 90
                        },
                        {
                            'is-danger':
                                DateTime.now().diff(
                                    props.row.draft.last_deployment,
                                    'minutes'
                                ).minutes < 30
                        }
                    ]"
                >
                    {{
                        props.row.draft.last_deployment
                            .setLocale("fr")
                            .toLocaleString(DateTime.DATETIME_SHORT)
                    }}
                </span>
            </b-table-column>
            <b-table-column
                field="remediation"
                label="Remediation"
                v-slot="props"
            >
                <span
                    :class="[
                        {
                            tag: props.row.remediation.is_oldest,
                            'is-large': props.row.remediation.is_oldest
                        },
                        {
                            'is-success':
                                DateTime.now().diff(
                                    props.row.remediation.last_deployment,
                                    'minutes'
                                ).minutes > 90
                        },
                        {
                            'is-danger':
                                DateTime.now().diff(
                                    props.row.remediation.last_deployment,
                                    'minutes'
                                ).minutes < 30
                        }
                    ]"
                >
                    {{
                        props.row.remediation.last_deployment
                            .setLocale("fr")
                            .toLocaleString(DateTime.DATETIME_SHORT)
                    }}
                </span>
            </b-table-column>
        </b-table>
        <b-loading
            :is-full-page="true"
            v-model="dataLoading"
            :can-cancel="true"
        ></b-loading>
    </div>
</template>

<style></style>
