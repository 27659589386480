<script>
export default {
    components: {},
    computed: {
        authLoading() {
            return this.$auth.loading;
        }
    },
    watch: {
        authLoading: function() {
            if (!this.$auth.isAuthenticated) {
                console.log("redirect to auth0");
                this.$auth.loginWithRedirect();
            }
        }
    }
};
</script>

<template>
    <div id="app">
        <b-loading
            :is-full-page="true"
            v-model="authLoading"
            :can-cancel="true"
        ></b-loading>
        <span v-if="$auth.isAuthenticated">
            <b-navbar type="is-primary">
                <template #brand>
                    <b-navbar-item tag="router-link" to="/versions">
                        Versions
                    </b-navbar-item>
                    <b-navbar-item tag="router-link" to="/web">
                        Web
                    </b-navbar-item>
                </template>
            </b-navbar>
            <router-view></router-view>
        </span>
    </div>
</template>

<style></style>
